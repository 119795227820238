export default class ServerConfig {
    static serverConfigInstance = null;
    
    /**
     * @returns {ServerConfig}
     */
    static getInstance() {
        if (ServerConfig.serverConfigInstance == null) {
            ServerConfig.serverConfigInstance = new ServerConfig();
        }

        return this.serverConfigInstance;
    }

    static longApiTimeout = 30 * 1000;

    getAPIEndpoint = () => {
        return process.env.REACT_APP_DH_SERVICE;
    }

    getContentEndpoint() {
        return process.env.REACT_APP_CONTENT_ENDPOINT;
    }

    getCDNResource() {
        return process.env.REACT_APP_CDN_FOLDER;
    }
}