import React from 'react';
import ServerConfig from './ServerConfig';
import { Row, Col, Alert, Container } from 'react-bootstrap';
import { Component } from 'react';
import axios from 'axios';

class Changelog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            __HTMLData: ""
        };
    }

    catchError(error) {
        console.log(error)
        if (this.props.ReportError !== undefined) {
            this.props.ReportError(error)
        } else {
            console.log("Error reporting broken.")
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        let remoteHost = ServerConfig.getInstance()
        let apiendpoint = remoteHost.getCDNResource();

        let testURL = {
            url: apiendpoint + "Changelog.html",
            method: 'GET',
            responseType: 'html'
        };

        const myInit = {
            mode: 'no-cors',
            crossSite: true,
            crossDomain: true 
        };

        axios(testURL, myInit)
            .then((data) => {
                // does this item have any other component needs?
                this.setState({ 
                    __HTMLData: data.data }, () => {
                        console.log(this.state.__HTMLData)
                    })
            })
            .catch((error) => this.catchError(error))
    }

    render() {
        return (
            <Container >
                <Row>
                    <Col>
                        <Alert>
                            <div dangerouslySetInnerHTML={{__html: this.state.__HTMLData}} />
                        </Alert>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Changelog;
