import React, { Component } from 'react';
import {
    Link
} from "react-router-dom";
import { Col, Row, Container } from 'react-bootstrap';

import '../css/FAQ.css';

class FAQ extends Component {
    render() {
        return (
            <Container>
                <Row>
                    <Col className="faqContainer">
                        <div><h3>Frequently Asked Questions</h3></div>
                        <details open>
                            <summary>What is Dungeon Finder?</summary>
                            <div class="faq__content">
                                <p>
                                    Dungeon Finder is a Third-party game launcher for popular Standing Stone games, including Dungeons and Dragons Online and Lord of the Rings Online.
                                    Our launcher application allows players to launch their game clients directly into an active character from the Windows tray, without having to type in a 
                                    password or make a choice at the character selection screen. Just click your character's shortcut in your system tray and you're in game - no extra wait!</p>
                            </div>
                        </details>
                        <details>
                            <summary>How much does it cost?</summary>
                            <div class="faq__content">
                                <p>
                                    Dungeon Finder is a completely free.
                                </p>
                            </div>
                        </details>
                        <details>
                            <summary>Is Dungeon Finder an SSG product?</summary>
                            <div class="faq__content">
                                <p>
                                    No, it is not. We are neither formally endorsed nor disallowed. We did not and will not ask for permission. And if we did, they'd have to
                                    say no because, well, because lawyers say so. We are not affiliated with Standing Stone Games in any way.
                                </p>
                                <p>
                                    However, we strive to operate in SSG's good graces. That is, we
                                    strongly desire to not run afoul of their interests. We don't charge, because they could argue we're detracting
                                    from their revenue.
                                </p>
                            </div>
                        </details>
                        <details>
                            <summary>Does using this break the DDO/SSG EULA/ToS?</summary>
                            <div class="faq__content">
                                <p>
                                    Disclaimer: we are not lawyers and cannot offer legal advice.
                                </p>
                                <p>
                                    That said, to the best of our knowledge and understanding, using Dungeon Finder is not against any rules or terms of
                                    service with either Dungeons and Dragons Online or Standing Stone Games.
                                </p>
                            </div>
                        </details>
                        <details>
                            <summary>How do I use it?</summary>
                            <div class="faq__content">
                                <p>
                                    Grab the installer from the <Link to="/download">Download</Link> page, and install Dungeon Finder. Once installed, setup the path to your game client in the settings menu and then add an account. With your game path and account details properly saved, you should be able to launch your game (when the servers are up! You can check here: <a href="https://ddoaudit.com">DDOAudiot</a>).
                                </p>
                            </div>
                        </details>
                        <details>
                            <summary>What data do you collect?</summary>
                            <div class="faq__content">
                                <p>
                                    Absolutely none. If you wish to assist in helping us fix bugs, we may ask for your log files from time to time. These
                                    will contain some mostly-anonymized data about your characters, but nothing that poses any security risk.
                                </p>
                            </div>
                        </details>
                        <details>
                            <summary>How can I contact the people that made this?</summary>
                            <div class="faq__content">
                                <p>
                                    Join our <a href="https://discord.gg/bfMZnbz">discord</a> - we're quite chatty and happy to talk to our users.
                                </p>
                            </div>
                        </details>
                        <details>
                            <summary>I can sling code, can I help improve this tool?</summary>
                            <div class="faq__content">
                                <p>
                                    Do you understand terms like WinForms and Web API? Can you code in a dotnet language? Then YES! Best way to get started
                                    is to join our discord and contact RabidSquirrel.
                                </p>
                            </div>
                        </details>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default FAQ;