import React from 'react';
import {
  Router,
  Switch,
  Route
} from "react-router-dom";
import history from "./utils/history";

import Nav from './Nav';
import '../css/App.css';

import { Container, Col, Row } from 'react-bootstrap';

// Route objects:
import Home from './Home';
import FAQ from './FAQ';
import Download from './Download';
import Footer from './Footer';
import Changelog from './Changelog';

// App functions as a request router

// PrivateRoute a wrapper authenticated pages, use when you need to be logged in.

// You can use the Auth0 library in Route as well to include both authenticated and non-authenticated components in a standard route.

function App() {

  return (
    <Container fluid>
      <Row>
        <Col>
          <Router history={history}>
            <Nav />
            <Row>
              <Switch>
                <Route path="/faq" component={FAQ} />
                <Route path="/download" component={Download} />
                <Route path="/changelog" component={Changelog} />
                <Route path="/" component={Home} />
              </Switch>
            </Row>
          </Router>
        </Col>
      </Row>
      <Row>
        <Col>
          <Footer/>
        </Col>
      </Row>
    </Container>
  );
}

export default App;
