import React from 'react';
import { Container, Row } from 'react-bootstrap';
import FAQ from './FAQ';

export default function Home() {
    return (
        <Container fluid>
            <Row>                
                <FAQ />
            </Row>
        </Container>
    );
}
