import React from 'react';

export default function Footer() {    
    return (
      <div id="paypal-footer">

      </div>
    );
}


