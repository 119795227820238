import React from 'react';
import {
    Link
} from "react-router-dom";
import { Container, ButtonGroup, Button } from 'react-bootstrap';
import VoKLogo from '../images/vok_logo.png'

/*
*   Top Nav Bar
*/
export default function Nav() {
    return (
        <Container>
            <div className="headerRow">
                <div className="row">
                    <div className="media d-flex align-items-center voklogo">
                        <img src={VoKLogo}
                            alt="Dungeon Finder" />
                        <div className="media-body logo-text voklogoText">
                            <h4 className="m-0 text-light">Dungeon Finder</h4>
                            <p className="font-weight-light mb-0 text-light">Game Launcher for SSG Games</p>
                        </div>
                    </div>
                    <ButtonGroup aria-label="Navigation">
                        <nav>
                            <ul>
                                <li>
                                    <Link to="/"><Button variant="dark">Home</Button></Link>
                                </li>                                
                                <li>
                                    <Link to="/download"><Button variant="dark">Download</Button></Link>
                                </li>
                            </ul>
                        </nav>
                    </ButtonGroup>
                </div>
            </div>
        </Container>
    );
}