import React from 'react';
import {
    Link
} from "react-router-dom";
import { Row, Col, Alert, Container } from 'react-bootstrap';

export default function Download() {
    return (
        <Container >
            <Row>
                <Col>
                    <Alert>
                        <h3>Dungeon Finder</h3>
                        <p>
                            You can download the latest release at any time, <a href="https://s3.amazonaws.com/downloads.dungeonhelper.com/public/DF/Dungeon_Finder.msi">here</a>.
                            <br/><br/>Additionally, you can also check out our <Link to="/changelog">changelog</Link> for the 
                            latest information on versions, changes, bug-fixes, and features.
                        </p>
                    </Alert>
                </Col>
            </Row>
        </Container>
    );
}
